:root {
  --body-font-family: "Inter", Helvetica;
}

.btn-cover {
  min-height: 100%;
  min-width: 100%;
  background: transparent;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  left: 0px;
}

.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* height: 77px; */
  padding: 8px 88px;
  align-self: stretch;
  background: #F2F0F0;
  box-sizing: border-box;
}

.button-navbar-active {
  display: flex;
  width: 125px;
  /* height: 50px; */
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  background: #FFF;

  color: #141414;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 24px */
}

.button-navbar-active-non {
  display: flex;
  width: 166px;
  /* height: 50px; */
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;

  color: #7E7E81;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 24px */
}

.d-flex {
  display: flex;

}

.flex-column {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.button-navbar-explore {
  cursor: pointer;
  display: flex;
  /* height: 52px; */
  padding: 14px 24px;
  justify-content: center;
  align-items: center;

  border-radius: 6px;
  background: var(--Light-Mode-Background-Primary, #319795);

  color: var(--Light-Mode-Background-Default, #FFF);

  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  /* 16px */
}

.jumbotron {
  width: 100%;
  height: 806px;
  flex-shrink: 0;

  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.55) 100%); */

  padding: 157px 88px;
  box-sizing: border-box;
}

.jumbotron-title {
  color: #319795;
  font-family: "Inter", Helvetica;
  font-size: 58.497px;
  font-style: normal;
  font-weight: 700;
  line-height: 68.247px;
  /* 116.667% */
}

.jumbotron-title-sub {
  font-family: "Inter", Helvetica;
  font-size: 58.497px;
  font-style: normal;
  font-weight: 700;
  line-height: 68.247px;
  /* 116.667% */

  background: linear-gradient(90deg, #319795 11%, #FFF 56%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.jumbotron-desck {
  color: #FFF;
  max-width: 800px;
  /* Header/H2 */
  font-family: "Inter", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 33.6px */
}

.gap-16px {
  gap: 16px;
}

.countdouwn-wrap {
  display: flex;
  width: fit-content;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #FCFCFC;
  min-width: 50px;
}

.countdouwn-days-value {
  color: #141414;
  text-align: center;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 24px */
  letter-spacing: -0.48px;
}

.countdouwn-days {
  color: #141414;
  text-align: center;
  font-family: "Inter", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 15px */
  letter-spacing: -0.3px;
}

.gap-4px {
  gap: 4px;
}

.why-wrap {
  flex-shrink: 0;
  background: #FFF;
  padding: 73px 0px 73px 88px;
}

.why-title {
  color: #319795;
  font-family: "Inter", Helvetica;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 72px */
  letter-spacing: -1.44px;
}

.why-title-sub {
  color: var(--Light-Mode-Text-Default, #202020);

  /* Header/H */
  font-family: "Inter", Helvetica;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 44.8px */
  width: 785px;
}

.why-people-title {
  color: var(--Light-Mode-Text-Default, #202020);
  text-align: left;

  /* Header/H4 */
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 25.2px */
  width: 450px;
}

.gap-32px {
  gap: 32px;
}

.tf-wrap-title {
  color: var(--Light-Mode-Text-Default, #202020);
  text-align: center;

  /* Header/H */
  font-family: "Inter", Helvetica;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 44.8px */
  /* width: 415px; */
}

.tf-wrap-title-sub {
  color: var(--Light-Mode-Text-Default, #202020);
  text-align: center;

  /* Header/H5 */
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  /* 22.4px */
  width: 900px;
}

.tf-wrap {
  display: flex;
  /* width: 1440px; */
  /* height: 752px; */
  padding: 80px 88px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: #F2F0F0;
}

.tf-wrap-value {
  color: var(--Light-Mode-Text-Default, #202020);
  text-align: center;

  /* Header/H2 */
  font-family: "Inter", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 33.6px */
  width: 335px;
}

.tf-wrap-value-sub {
  color: var(--Light-Mode-Text-Default, #202020);
  text-align: center;

  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 22.4px */
  width: 410px;
}

.gap-8px {
  gap: 8px;
}

.mt-74px {
  margin-top: 74px;
}

.talent-wrap {
  display: flex;
  padding: 160px 88px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  background: #FFF;
}

.w-100 {
  width: 100%;
}

.mt-80px {
  margin-top: 80px;
}

.talent-wrap-title {
  color: var(--Light-Mode-Text-Default, #202020);

  /* Header/H4 */
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 25.2px */
}

.talent-wrap-title-sub {
  color: var(--Light-Mode-Text-Default, #202020);
  text-align: justify;

  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 22.4px */
  width: 300px;
}

.mt-96px {
  margin-top: 96px;
}

.justify-center {
  justify-content: center;
}

.wrap-personal {
  /* width: 1440px;
  height: 752px; */
  flex-shrink: 0;
  background: #fff;
  padding: 80px 88px;
  display: flex;
}

.gap-12px {
  gap: 12px;
}

.personal-active {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  background: #F2F0F0;
}

.justify-between {
  justify-content: space-between;
}

.w-472px {
  width: 472px;
}

.personal-title {
  color: #000;
  font-family: "Inter", Helvetica;
  font-size: 42px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 58.8px */
}

.mb-54px {
  margin-bottom: 54px;
}

.personal-title-sub {
  color: var(--Light-Mode-Text-Default, #202020);
  text-align: center;

  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 25.2px */
}

.personal-activenon {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.cursor-pointer {
  cursor: pointer;
}

.personal-title-sub-sub {
  color: var(--Light-Mode-Text-Default, #202020);

  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 22.4px */
}

.wrap-unlock {
  display: flex;
  /* width: 1440px;
  height: 402px; */
  /* padding: 80px 88px;  */
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), url('./assets/png/Frame.png') lightgray 50% / cover no-repeat;
}

.wrap-unlock-title {
  color: var(--Light-Mode-Text-On-primary, #FFF);

  /* Header/H */
  font-family: "Inter", Helvetica;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 44.8px */
}

.wrap-unlock-title-sub {
  color: var(--Light-Mode-Text-On-primary, #FFF);

  /* Header/H2 */
  font-family: "Inter", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 33.6px */
}

.Webinar {
  display: flex;
  /* width: 1440px;
  height: 452px; */
  padding: 64px 88px;
  justify-content: center;
  align-items: flex-start;
  gap: 64px;
  flex-shrink: 0;
  justify-content: space-between;
  flex-direction: column;
}

.Webinar-title {
  color: var(--Light-Mode-Text-Default, #202020);

  /* Header/H3 */
  font-family: "Inter", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 28px */
}

.Webinar-title-sub {
  color: var(--Light-Mode-Text-Default, #202020);

  /* Text/Body1-Regular */
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 22.4px */
}

.Webinar-info {
  color: var(--Light-Mode-Text-Default, #202020);

  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 22.4px */
}

.card-why {
  display: flex;
  width: 100%;
  padding: 16px;
  /* justify-content: center; */
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  background: #F2F0F0;
}

.py-pc {
  padding-top: 20px;
  padding-bottom: 20px;

}

.title-vid {
  color: #319795;
  font-family: "Inter", Helvetica;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 72px */
  letter-spacing: -1.44px;
}

.title-vid-wrapp {
  height: 741px;
  flex-shrink: 0;
  align-self: stretch;
  background: #F2F0F0;
  display: flex;
  gap: 55px;
  padding-right: 55px;
  align-items: center;
}

.title-vid-sub {
  color: var(--Light-Mode-Text-Default, #202020);

  /* Header/H3 */
  font-family: "Inter", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 28px */
}

.button-navbar-explore-dis {
  display: flex;
  /* height: 52px; */
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: var(--Light-Mode-Background-Disabled, #E9E9EB);

  color: var(--Light-Mode-Text-Secondary, #89898A);

  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  /* 16px */
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.55) !important;
  /* padding: 32px !important; */
  z-index: 99999999;
}

.ReactModal__Content {
  padding: 12px !important;
  width: 350px !important;
  border-radius: 8px !important;
  background: var(--Light-Mode-Background-Default, #FFF) !important;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25) !important;
}

.justify-end {
  justify-content: flex-end;
}

.card-modal {
  display: flex;
  width: 100%;
  /* padding: 32px; */
  flex-direction: column;
  /* align-items: center; */
  /* gap: 16px; */
  box-sizing: border-box;
  z-index: 999999;
}

.modal-title {
  color: var(--Light-Mode-Text-Default, #202020);
  text-align: center;
  font-family: "Inter", Helvetica;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 22.4px */
  width: 200px;

}

.modal-button {
  display: flex;
  /* height: 34px; */
  padding: 8px 12px;
  justify-content: center;
  align-items: center;

  border-radius: 6px;
  background: var(--Light-Mode-Background-Primary, #319795);

  color: var(--Light-Mode-Background-Default, #FFF);

  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  /* 12px */
  cursor: pointer;
}

.wrap-vdioe-mobile {
  border-radius: 16px;
  overflow: hidden;
  height: 100px;
}

.neddHelp-title {
  color: #319795;
  font-family: "Inter", Helvetica;
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.neddHelp-title-wrapp {
  height: 30px;
  padding: 0px 8px;
  gap: 8px;
  width: fit-content;
  border-radius: 100px;
  background: #FFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
}

/* ================= */
.lp-navbar-wrapp {
  background: white;
}

.lp-navbar {
  display: flex;
  align-items: center;
  align-self: stretch;
  padding-top: 16px;
  padding-bottom: 16px;
}

.justify-content-end {
  justify-content: flex-end;
}

.custom-button {
  width: 100%;
  /* Default width for all breakpoints */
}

/* break points */
.lp-button-navbar {
  display: flex;
  height: 36px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;

  border-radius: 6px;
  background: var(--Light-Mode-Background-Primary, #319795);

  color: #FFF;

  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  /* 14px */
}

.wrap-logo {
  width: 102.373px;
  height: 16px;
}

.wrap-jumbotron {
  position: relative;
  max-height: 360px;
  overflow: hidden;
  height: 360px;
}

.zIndex {
  z-index: 1;
}

.wrap-jumbotron-desc {
  display: flex;
  /* padding: 151px 20px; */
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  height: 100%;
}

.img-jumbotron {
  width: 100%;
  height: 360px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.lp-title-one {
  color: #319795;
  /* Header/H3 */
  font-family: "Inter", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 28px */
}

.lp-title-two {
  font-family: "Inter", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 28px */
  background: linear-gradient(90deg, #319795 11%, #FFF 56%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.lp-title-desc {
  color: #FFF;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 19.6px */
}

.title-vid-new {
  color: #319795;

  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 25.2px */
}

.title-vid-sub-new {
  color: var(--Light-Mode-Text-Default, #202020);

  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 16.8px */
}

.bg-secondary-app {
  background: #F2F0F0;
}

.py-20px {
  padding-top: 20px;
  padding-bottom: 20px;
}

.mt-20px {
  margin-top: 20px;
}

.wrap-vdioe-tablet {
  display: none;
  border-radius: 16px;
  overflow: hidden;
  height: 176px;
}

.wrap-vdioe-pc {
  display: none;
  border-radius: 16px;
  overflow: hidden;
  height: 293px;
}

.vid-desc {
  color: #319795;
  font-feature-settings: 'liga' off, 'clig' off;

  font-family: "Inter", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 10px */
  margin-top: 7px;
}

.why-title-new {
  color: #319795;

  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 25.2px */
}

.why-title-sub-new {
  color: var(--Light-Mode-Text-Default, #202020);

  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 16.8px */
}

.why-people-title-new {
  color: var(--Light-Mode-Text-Default, #202020);
  font-feature-settings: 'liga' off, 'clig' off;

  font-family: "Inter", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  /* 10px */
}

.mt-8px {
  margin-top: 8px;
}

.pb-8px {
  padding-bottom: 8px;
}

.p-8px {
  padding: 8px;
}

.p-4px {
  padding: 4px;
}

.p-20px {
  padding: 20px;
}

.img-why-talent {
  max-width: 100%;
  max-height: 190px;
}

.p-relative {
  position: relative;
}

.img-why-talent-pc {
  position: absolute;
  bottom: 0px;
  right: -200px;
  max-width: 100%;
  max-height: 721px;
  display: none;
}

.transfor-new {
  color: var(--Light-Mode-Text-Primary, #319795);
  text-align: center;

  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 25.2px */
}

.transfor-sub-new {
  color: var(--Light-Mode-Text-Default, #202020);
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;

  font-family: "Inter", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  /* 10px */
}

.tf-wrap-value-new {
  color: var(--Light-Mode-Text-Default, #202020);
  text-align: center;

  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 16.8px */
}

.tf-wrap-title-sub-new {
  color: var(--Light-Mode-Text-Default, #202020);
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;

  font-family: "Inter", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 10px */
}

.height-mobile {
  max-height: 150px;
  overflow: hidden;
}

.wrap-unlock-title-new {
  color: var(--Light-Mode-Text-On-primary, #FFF);

  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  /* 22.4px */
}

.wrap-unlock-title-sub-new {
  color: var(--Light-Mode-Text-On-primary, #FFF);

  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 16.8px */
}

.Webinar-title-new {
  color: var(--Light-Mode-Text-Default, #202020);

  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  /* 16.8px */
}

.img-logo {
  height: 28px;
}

.gap-footer {
  gap: 8px;
}

.Webinar-info-new {
  color: var(--Light-Mode-Text-Default, #202020);
  font-feature-settings: 'liga' off, 'clig' off;

  font-family: "Inter", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 10px */
}

.vid-pop-up-wrap {
  min-width: 100%;
}

@media (min-width: 576px) {

  /* sm: 576px ke atas */
  .vid-pop-up-wrap {
    min-width: 100%;
    min-height: 300px;
  }

  .neddHelp-title {
    color: #319795;
    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .neddHelp-title-wrapp {
    height: 40px;
    padding: 0px 8px;
    gap: 8px;
    width: fit-content;
    border-radius: 100px;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
  }

  .ReactModal__Content {
    padding: 32px !important;
    width: 582px !important;
    border-radius: 8px !important;
    background: var(--Light-Mode-Background-Default, #FFF) !important;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25) !important;
  }

  .Webinar-info-new {
    color: var(--Light-Mode-Text-Default, #202020);

    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 16.8px */
  }

  .Webinar-title-new {
    color: var(--Light-Mode-Text-Default, #202020);

    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    /* 22.4px */
  }

  .wrap-unlock-title-sub-new {
    color: var(--Light-Mode-Text-On-primary, #FFF);

    font-family: "Inter", Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 19.6px */
  }

  .wrap-unlock-title-new {
    color: var(--Light-Mode-Text-On-primary, #FFF);

    font-family: "Inter", Helvetica;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 39.2px */
  }

  .tf-wrap-title-sub-new {
    color: var(--Light-Mode-Text-Default, #202020);
    text-align: center;

    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 16.8px */
  }

  .tf-wrap-value-new {
    color: var(--Light-Mode-Text-Default, #202020);
    text-align: center;

    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    /* 22.4px */
  }

  .transfor-sub-new {
    color: var(--Light-Mode-Text-Default, #202020);
    text-align: start;
    /* Header/H5 */
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    /* 22.4px */
  }

  .transfor-new {
    text-align: start;
    color: var(--Light-Mode-Text-Primary, #319795);
    font-family: "Inter", Helvetica;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 58.8px */
  }

  .py-pc {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .img-why-talent-pc {
    display: none;
  }

  .img-why-talent {
    max-height: 290px;
  }

  .why-people-title-new {
    color: var(--Light-Mode-Text-Default, #202020);

    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 16.8px */
  }

  .why-title-sub-new {
    color: var(--Light-Mode-Text-Default, #202020);

    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 25.2px */
  }

  .why-title-new {
    color: #319795;
    font-family: "Inter", Helvetica;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /* 60px */
    letter-spacing: -1.2px;
  }

  .vid-desc {
    color: #319795;

    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 16.8px */
  }

  .wrap-vdioe-pc {
    display: none;
  }

  .wrap-vdioe-tablet {
    display: block;
  }

  .wrap-vdioe-mobile {
    display: none;
  }

  .title-vid-sub-new {
    color: var(--Light-Mode-Text-Default, #202020);

    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 25.2px */
  }

  .title-vid-new {
    /* width: 300px; */
    color: #319795;
    font-family: "Inter", Helvetica;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /* 60px */
    letter-spacing: -1.2px;
  }

  .lp-title-desc {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 25.2px */
  }

  .lp-title-two {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px;
    /* 115% */
  }

  .lp-title-one {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px;
    /* 115% */
  }

  .img-jumbotron {
    height: 510px;
  }

  .wrap-jumbotron {
    position: relative;
    max-height: 510px;
    height: 510px;
    overflow: hidden;
  }

  .lp-button-navbar {
    display: flex;
    height: 36px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;

    border-radius: 6px;
    background: var(--Light-Mode-Background-Primary, #319795);

    color: #FFF;

    font-family: "Inter", Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 14px */
  }

  .wrap-logo {
    width: 150px;
    height: 23.444px;
  }

}

@media (min-width: 992px) {
  .vid-pop-up-wrap {
    min-width: 100%;
    min-height: 500px;
  }

  .Webinar-info-new {
    color: var(--Light-Mode-Text-Default, #202020);

    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
  }

  .gap-footer {
    gap: 32px;
  }

  .img-logo {
    height: 47px;
  }

  .Webinar-title-new {
    color: var(--Light-Mode-Text-Default, #202020);

    font-family: "Inter", Helvetica;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 28px */
  }

  .wrap-unlock-title-sub-new {
    color: var(--Light-Mode-Text-On-primary, #FFF);

    font-family: "Inter", Helvetica;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 33.6px */
  }

  .wrap-unlock-title-new {
    color: var(--Light-Mode-Text-On-primary, #FFF);

    font-family: "Inter", Helvetica;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 44.8px */
  }

  .height-mobile {
    max-height: 250px;
  }

  .py-20px {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .tf-wrap-title-sub-new {
    color: var(--Light-Mode-Text-Default, #202020);
    text-align: center;

    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
  }

  .tf-wrap-value-new {
    color: var(--Light-Mode-Text-Default, #202020);
    text-align: center;

    font-family: "Inter", Helvetica;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 33.6px */
  }

  .transfor-sub-new {
    color: var(--Light-Mode-Text-Default, #202020);
    text-align: center;

    font-family: "Inter", Helvetica;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 28px */
  }

  .transfor-new {
    color: var(--Light-Mode-Text-Primary, #319795);
    text-align: center;
    font-family: "Inter", Helvetica;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 58.8px */
  }

  .img-why-talent-pc {
    display: block;
  }

  .min-h-pc-721px {
    min-height: 750px;
  }

  .img-why-talent {
    display: none;
  }

  .why-people-title-new {
    color: var(--Light-Mode-Text-Default, #202020);

    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 22.4px */
  }

  /* lg: 992px ke atas */
  .why-title-sub-new {
    color: var(--Light-Mode-Text-Default, #202020);

    font-family: "Inter", Helvetica;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 28px */
  }

  .why-title-new {
    color: var(--Light-Mode-Background-Primary, #319795);
    font-family: "Inter", Helvetica;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /* 72px */
    letter-spacing: -1.44px;
  }

  .wrap-vdioe-pc {
    display: block;
  }

  .wrap-vdioe-tablet {
    display: none;
  }

  .wrap-vdioe-mobile {
    display: none;
  }

  .title-vid-sub-new {
    color: var(--Light-Mode-Text-Default, #202020);

    font-family: "Inter", Helvetica;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 28px */
  }

  .title-vid-new {
    /* width: 400px; */
    color: var(--Light-Mode-Background-Primary, #319795);
    font-family: "Inter", Helvetica;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /* 72px */
    letter-spacing: -1.44px;
  }

  .lp-title-desc {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 33.6px */
  }

  .lp-title-two {
    font-size: 58.497px;
    font-style: normal;
    font-weight: 700;
    line-height: 68.247px;
    /* 116.667% */
  }

  .lp-title-one {
    font-size: 58.497px;
    font-style: normal;
    font-weight: 700;
    line-height: 68.247px;
    /* 116.667% */
  }

  .img-jumbotron {
    height: 675px;
  }

  .wrap-jumbotron {
    position: relative;
    max-height: 675px;
    height: 675px;
    overflow: hidden;
  }

  .lp-button-navbar {
    display: flex;
    height: 52px;
    padding: 14px 24px;
    justify-content: center;
    align-items: center;

    border-radius: 6px;
    background: var(--Light-Mode-Background-Primary, #319795);

    color: #FFF;

    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 16px */
    /* 16px */
  }

  .wrap-logo {
    width: 191.949px;
    height: 30px;
  }

  .modal-title {
    color: var(--Light-Mode-Text-Default, #202020);
    text-align: center;

    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 22.4px */
    width: 400px;

  }

  .ReactModal__Content {
    padding: 32px !important;
    width: 900px !important;
    border-radius: 8px !important;
    background: var(--Light-Mode-Background-Default, #FFF) !important;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25) !important;
  }
}

/* faq */
.faq-title {
  color: #FFF;
  font-family: "Inter", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 30px */
  letter-spacing: -0.6px;
}

.faq-title-sub {
  color: #FFF;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;

  font-family: "Inter", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 10px */
}

.h-banner-faq {
  min-height: 330px;
}

.border-top {
  border-top: 1px solid var(--Light-Mode-Background-Tertiary, #D8D8DA);
  padding-top: 16px;
  width: 100%;
}

.wrap-one-faq {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  background: #FFF;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.20);
}

.wrap-one-faq-title {
  color: var(--Light-Mode-Text-Default, #202020);
  font-feature-settings: 'liga' off, 'clig' off;

  font-family: "Inter", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  /* 10px */
}

.wrap-one-faq-title-sub {
  color: var(--Light-Mode-Text-Default, #202020);
  font-feature-settings: 'liga' off, 'clig' off;

  font-family: "Inter", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 10px */
}

.wrap-action-mobile {
  display: block;
}

.wrap-action-tablet {
  display: none;
}

.wrap-action-web {
  display: none;
}

.wrap-routing-nav {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
}

.wrap-routing {
  /* width: fit-content;
  height: fit-content; */
  display: flex;
  /* padding: 8px 16px; */
  justify-content: center;
  align-items: center;
  /* gap: 10px; */
  flex: 1 0 0;
  align-self: stretch;

  /* border-radius: 20px; */
  /* background: red; */
}

.respon-tab-web {
  display: none;
}

.respon-tab-web-IHumberger {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.wrap-routing-button {
  display: flex;
  /* padding: 8px 16px; */
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 20px;
  background: #F2F0F0;
}

.wrap-routing-button-home {

  display: flex;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 20px;
  background: #1D2C25;

  color: var(--Light-Mode-Text-On-primary, #FFF);
  font-family: "Inter", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 16.8px */
}

.wrap-routing-button-faq {
  display: flex;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;

  color: var(--Light-Mode-Text-Secondary, #89898A);

  font-family: "Inter", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 16.8px */
}

.wrap-routing-button-home-page-faq {

  display: flex;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;

  color: var(--Light-Mode-Text-Secondary, #89898A);
  font-family: "Inter", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 16.8px */
}

.wrap-routing-button-faq-page-faq {
  display: flex;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 20px;
  background: #1D2C25;

  color: var(--Light-Mode-Text-On-primary, #FFF);
  font-family: "Inter", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 16.8px */
}

@media (min-width: 576px) {
  .respon-mobile-faq {
    display: none;
  }

  .respon-tab-web-IHumberger {
    display: none;
  }

  .respon-tab-web {
    display: flex;
  }

  .wrap-routing-button {
    display: flex;
    /* padding: 8px 16px; */
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 20px;
    background: #F2F0F0;
  }

  .wrap-routing-button-home {

    display: flex;
    padding: 8px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;

    border-radius: 20px;
    background: #1D2C25;

    color: var(--Light-Mode-Text-On-primary, #FFF);
    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 16.8px */
  }

  .wrap-routing-button-faq {
    display: flex;
    padding: 8px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;

    color: var(--Light-Mode-Text-Secondary, #89898A);

    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 16.8px */
  }

  .wrap-routing-button-home-page-faq {

    display: flex;
    padding: 8px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;

    color: var(--Light-Mode-Text-Secondary, #89898A);
    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 16.8px */
  }

  .wrap-routing-button-faq-page-faq {
    display: flex;
    padding: 8px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;

    border-radius: 20px;
    background: #1D2C25;

    color: var(--Light-Mode-Text-On-primary, #FFF);
    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 16.8px */
  }

  .wrap-one-faq {
    gap: 8px;
  }

  .wrap-action-mobile {
    display: none;
  }

  .wrap-action-tablet {
    display: block;
  }

  .wrap-action-web {
    display: none;
  }

  .wrap-one-faq-title {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 16.8px */
  }

  .wrap-one-faq-title-sub {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 10px */
  }

  .h-banner-faq {
    height: 527px;
  }

  .faq-title {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /* 60px */
    letter-spacing: -1.2px;
  }

  .faq-title-sub {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 25.2px */
  }
}

@media (min-width: 992px) {
  .respon-mobile-faq {
    display: none;
  }

  .respon-tab-web-IHumberger {
    display: none;
  }

  .respon-tab-web {
    display: flex;
  }

  .wrap-routing-button {
    display: flex;
    /* padding: 8px 16px; */
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 20px;
    background: #F2F0F0;
  }

  .wrap-routing-button-home {

    display: flex;
    padding: 8px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;

    border-radius: 20px;
    background: #1D2C25;

    color: var(--Light-Mode-Text-On-primary, #FFF);
    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 16.8px */
  }

  .wrap-routing-button-faq {
    display: flex;
    padding: 8px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;

    color: var(--Light-Mode-Text-Secondary, #89898A);

    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 16.8px */
  }

  .wrap-one-faq {
    gap: 16px;
  }

  .wrap-action-mobile {
    display: none;
  }

  .wrap-action-tablet {
    display: none;
  }

  .wrap-action-web {
    display: block;
  }

  .wrap-one-faq-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 25.2px */
  }

  .wrap-one-faq-title-sub {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
  }

  .faq-title {

    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /* 72px */
    letter-spacing: -1.44px;
  }

  .faq-title-sub {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 28px */
  }
}